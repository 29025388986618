<template>
  <div class="smb-client-wrapper">
    <template>
      <SmbTitles
        is-show-title
        is-show-sub-title
        title="Tips"
        sub-title="Select the topic of the tip you want us to generate for you."
      />
      <div class="buttons-wrapper">
        <div class="tips" v-for="menuItem in menuTips" :key="menuItem.id">
          <div class="tips__title">{{ menuItem.title }}</div>
          <div class="tips__buttons">
            <template v-for="button in menuItem.buttons">
              <SmbGeneralButton
                :key="button.id"
                :text="button.name"
                :emitName="button.emitName"
                :isLoading="button.isLoading"
                @click="
                  checkIsNeedBrandingUpdatesBeforeSurpriseMe(
                    button,
                    menuItem.subjectId
                  )
                "
              />
            </template>
          </div>
        </div>
      </div>
      <div class="smb-client-wrapper__footer">
        <SmbActionBtn
          text="Back"
          @click="$router.push({ name: 'generate-content' })"
        />
      </div>
      <SmbAddBrandingDetailsPopUp
        :isShow="isShowBrandingPopUp"
        :actionType="isNeedToUpdateBranding"
        @close="isShowBrandingPopUp = false"
        :buttonsData="{
          button: lastClickedBtnData,
          lastSubId: lastSubId,
          type: getCurrentCreationContentType,
        }"
        :emit-name="emitNameForActionAfterPopup"
        @clickOnBtnHandler="clickOnBtnHandler(lastClickedBtnData, lastSubId)"
      />
    </template>
  </div>
</template>

<script>
import H from "@/utils/helper";
import smbMethods from "@/mixins/smbMethods";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { TIPS_MENU, POP_UP_TEXT } from "@/constants/smb-client";

import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbGeneralButton from "@/components/smb-client/SmbGeneralButton";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbAddBrandingDetailsPopUp from "@/components/smb-client/SmbAddBrandingDetailsPopUp.vue";
import LogRocket from "logrocket";

export default {
  name: "Tips",
  components: {
    SmbTitles,
    SmbGeneralButton,
    SmbActionBtn,
    SmbAddBrandingDetailsPopUp,
  },
  mixins: [smbMethods],
  data: () => ({
    menuTips: TIPS_MENU,
    isShowBrandingPopUp: false,
    isLoading: false,
    nameOfLastAction: "",
    lastSubId: null,
    lastClickedBtnData: {},
    emitNameForActionAfterPopup: "",
  }),
  computed: {
    ...mapGetters("creationContentType", ["getCurrentCreationContentType"]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    popUpTextFirstPath() {
      return `The new ${this.nameOfLastAction.toLowerCase()} ${
        this.getCurrentCreationContentType
      }  is now being generated.`;
    },
    popUpTextSecondPath() {
      return this.getCurrentSmbCustomer.primary_phone
        ? `You will be notified via SMS to ${H.formatPhoneNumber(
            this.getCurrentSmbCustomer.primary_phone,
            false
          )} once ready (about 1 min).`
        : "You can check it out in the Pending content section in ~30 seconds.";
    },
    popUpText() {
      return `${this.popUpTextFirstPath} ${this.popUpTextSecondPath}`;
    },
  },
  async created() {
    if (
      this.$route.params &&
      this.$route.params.emitName &&
      !H.isEmpty(this.$route.params.buttonsData)
    ) {
      let { button, lastSubId, type = "post" } = this.$route.params.buttonsData;
      this.setCurrentCreationContentType(type);
      await this[this.$route.params.emitName](button, lastSubId);
    }
  },
  methods: {
    ...mapActions("smbClientSelection", ["createPosts"]),
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    ...mapMutations("creationContentType", ["setCurrentCreationContentType"]),
    async clickOnBtnHandler(buttonData, subjectId) {
      this.isShowBrandingPopUp = false;
      try {
        LogRocket.track("content_generation_button");
        buttonData.isLoading = true;
        let params = {
          customerId: this.getCurrentSmbCustomer.id,
          topicSubId: [subjectId],
          subject: buttonData.name,
          bySmb: true,
          type: this.getCurrentCreationContentType,
        };

        await this.createPosts(params);
        this.nameOfLastAction = buttonData.name;
        this.setPopupSettings({
          isShowPopup: true,
          popUpText: this.popUpText,
        });
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        buttonData.isLoading = false;
      }
    },
    async checkIsNeedBrandingUpdatesBeforeSurpriseMe(buttonData, subjectId) {
      if (this.isNeedToUpdateBranding) {
        this.isShowBrandingPopUp = true;
        this.emitNameForActionAfterPopup = "clickOnBtnHandler";
        this.lastClickedBtnData = buttonData;
        this.lastSubId = subjectId;
      } else {
        await this.clickOnBtnHandler(buttonData, subjectId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  @media screen and (min-width: 760px) {
    ::v-deep {
      .smb-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #92c4ff;
        padding: 120px 20px 45px 20px;
        &__title {
          font-weight: 700;
          font-size: 36px;
          line-height: 140%;
        }
        &__sub-title {
          font-weight: 400;
          font-size: 30px;
          line-height: 140%;
          color: #fff;
          margin-top: 80px;
        }
      }
    }
  }
  .buttons-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 20px;
    flex: 1;
    @media screen and (min-width: 760px) {
      padding: 35px 20px;
      max-width: 880px;
    }
  }
  &__footer {
    @media screen and (min-width: 760px) {
      .button-item {
        max-width: 300px;
        margin: 0 auto 30px auto;
      }
    }
  }
}

.tips {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #1f2325;
    margin-bottom: 10px;
  }
  &__buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 10px 5px;
    @media screen and (min-width: 760px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px 45px;
      ::v-deep {
        .btn-text {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
