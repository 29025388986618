var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smb-client-wrapper"},[[_c('SmbTitles',{attrs:{"is-show-title":"","is-show-sub-title":"","title":"Tips","sub-title":"Select the topic of the tip you want us to generate for you."}}),_c('div',{staticClass:"buttons-wrapper"},_vm._l((_vm.menuTips),function(menuItem){return _c('div',{key:menuItem.id,staticClass:"tips"},[_c('div',{staticClass:"tips__title"},[_vm._v(_vm._s(menuItem.title))]),_c('div',{staticClass:"tips__buttons"},[_vm._l((menuItem.buttons),function(button){return [_c('SmbGeneralButton',{key:button.id,attrs:{"text":button.name,"emitName":button.emitName,"isLoading":button.isLoading},on:{"click":function($event){return _vm.checkIsNeedBrandingUpdatesBeforeSurpriseMe(
                  button,
                  menuItem.subjectId
                )}}})]})],2)])}),0),_c('div',{staticClass:"smb-client-wrapper__footer"},[_c('SmbActionBtn',{attrs:{"text":"Back"},on:{"click":function($event){return _vm.$router.push({ name: 'generate-content' })}}})],1),_c('SmbAddBrandingDetailsPopUp',{attrs:{"isShow":_vm.isShowBrandingPopUp,"actionType":_vm.isNeedToUpdateBranding,"buttonsData":{
        button: _vm.lastClickedBtnData,
        lastSubId: _vm.lastSubId,
        type: _vm.getCurrentCreationContentType,
      },"emit-name":_vm.emitNameForActionAfterPopup},on:{"close":function($event){_vm.isShowBrandingPopUp = false},"clickOnBtnHandler":function($event){return _vm.clickOnBtnHandler(_vm.lastClickedBtnData, _vm.lastSubId)}}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }